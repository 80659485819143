import React, { useState, useEffect, createContext } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import GalleryPhoto from '../components/GalleryPhoto'

import {default as PhotosApi} from '../api/Photos';

export const PhotosContext = createContext();

export const Photos = () => {

  const [photos, setPhotos] = useState([]);
  const [photosLoading, setPhotosLoading] = useState(true);
  const [currentPhotosPage, setCurrentPhotosPage] = useState(1);
  const [photosPages, setPhotosPages] = useState(1);


  const getPhotos = async (page = 1) => {
    const filters = {
      category: {
        name: "Photographies"
      }
    }
    const { data, meta } = await PhotosApi.getPhotos(page, 5, filters);
    setPhotos([...photos, ...data]);
    setPhotosLoading(false);
    setCurrentPhotosPage(meta.pagination.page)
    setPhotosPages(meta.pagination.pageCount)
  }

  const scrollBottom = useBottomScrollListener((ev) => {
    if (currentPhotosPage + 1 <= photosPages) {
      getPhotos(currentPhotosPage + 1);
    }
  });

  useEffect(() => {
    getPhotos()
  }, [])

  return (
    <main>
      <h2 className="content-title">Mes photographies</h2>
        { photosLoading && <p>Chargement des photos en cours ... </p>}
        <PhotosContext.Provider value={{ photos, setPhotos }} ref={scrollBottom}>
          <GalleryPhoto />
        </PhotosContext.Provider>
    </main>
  )
}