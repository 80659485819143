import React from 'react'
import { useNavigate } from 'react-router-dom';

import '../styles/NoPage.css';
import img_notfound from '../images/await.png';

const NoPage = () => {

	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

  return (
    <main className="not-found">
        <h2> Page indisponible </h2>
        <img src={img_notfound} alt="" />
        <p>
          La page que vous tentez d'atteindre n'est pas disponible.
        </p>
        <p>
          Merci de contacter l'administrateur du site si l'erreur persiste.
        </p>
        <button onClick={goBack}>Retour</button>
    </main>
  )
}

export default NoPage