import React from 'react'
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className='main-footer'>
        <p className='main-footer-content'> 
          Studio Audrey &copy; 2023 - Réalisé par <a href="https://maximebranger.fr">Maxime Branger</a>
        </p>
        <p className='main-footer-legals'>
          Toutes les images sont la propriété de Studio Audrey et ne peuvent pas être reproduites, modifiées ou utilisées sans autorisation préalable.
        </p>
    </footer>
  )
}

export default Footer