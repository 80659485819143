import Params from "../utils/params";
import flatenStrapiObject from '../utils/flattenStrapiObject';

export default class Photos {

    static async getPhotos(page, size, filters = {}) {
        const results = await Params.strapi.find("photographies", { 
            filters: filters,
            populate: {
                image: "*",
                category: "*"
            },
            pagination: {
              page: page,
              pageSize: size
            },
            sort: {}
        });

        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);

        return {data, meta};
    }

}