import { useState, useContext } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Params from "../utils/params";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { CallisContext } from '../pages/Callis';

import '../styles/Gallery.css';
import img_sad from '../images/sad.png';

const Gallery = () => {

  const { callis } = useContext(CallisContext);

  const [index, setIndex] = useState(-1);

  const slides = callis.map(item => {
    const { url, width, height } = item.image.formats.large;
    const url_full = Params.API_URL + url;
    return { src: url_full, width, height };
  });

  return (
    <section className="gallery">
      {
        callis.length === 0 && 
        <div className="gallery-nothing">
          <p>Aucune image à afficher</p>
          <img src={img_sad} alt="" />
        </div>
      }
      <PhotoAlbum layout="masonry" photos={slides} onClick={({ index }) => setIndex(index)} 
        columns={(containerWidth) => {
          if (containerWidth < 800) return 1;
          return 2;
        }}
      />
      <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />

    </section>
  )
}

export default Gallery