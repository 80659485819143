import Params from "../utils/params";
import flatenStrapiObject from '../utils/flattenStrapiObject';

export default class Articles {

    static async getArticle(page, size, filters = {}) {
        const results = await Params.strapi.find("articles", { 
            filters: filters,
            populate: "*",
            pagination: {
              page: page,
              pageSize: size
            },
            sort: {}
        });
        const data = flatenStrapiObject(results.data);
        const meta = flatenStrapiObject(results.meta);

        return {data, meta};
    }

}