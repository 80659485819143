import React, { useState, useEffect, createContext } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import GalleryCalli from '../components/GalleryCalli'

import {default as PhotosApi} from '../api/Photos';

export const CallisContext = createContext();

export const Callis = () => {

  const [callis, setCallis] = useState([]);
  const [callisLoading, setCallisLoading] = useState(true);
  const [currentCallisPage, setCurrentCallisPage] = useState(1);
  const [callisPages, setCallisPage] = useState(1);

  const getCallis = async (page = 1) => {
    const filters = {
      category: {
        name: "Calligraphies"
      }
    }
    const { data, meta } = await PhotosApi.getPhotos(page, 5, filters);
    setCallis(data);
    setCallisLoading(false);
    setCurrentCallisPage(meta.pagination.page)
    setCallisPage(meta.pagination.pageCount)
  }

  const scrollBottom = useBottomScrollListener((ev) => {
    if (currentCallisPage + 1 <= callisPages) {
      getCallis(currentCallisPage + 1);
    }
  });

  useEffect(() => {
    getCallis()
  }, [])

  return (
    <main>
      <h2 className="content-title">Mes calligraphies</h2>
      { callisLoading && <p>Chargement des photos en cours ... </p>}
        <CallisContext.Provider value={{ callis, setCallis }} ref={scrollBottom}>
          <GalleryCalli />
        </CallisContext.Provider>
    </main>
  )
}