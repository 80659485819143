import React, { useState, useEffect } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import {default as ArticlesApi} from '../api/Articles';
import Params from '../utils/params';

import '../styles/Home.css';

const Home = () => {
  
  const [article, setArticle] = useState([]);
  const [articleLoading, setArticleLoading] = useState(true);


  const getArticles = async () => {
    const filters = {
      category: {
        name: "Home"
      }
    }
    const { data, meta } = await ArticlesApi.getArticle(1, 1, filters);

    setArticle(data[0]);
    setArticleLoading(false);
  }

  useEffect(() => {
    getArticles()
  }, [])

  return (
    <main>
      { articleLoading && <p>Chargement en cours</p>}
      
      { !articleLoading && 
          <div>
            <h2 className="content-title">Bienvenue dans ma galerie !</h2>
            <picture>
              { article.cover.formats.small &&  <source srcSet={Params.API_URL+article.cover.formats.small.url} media="(width < 600px)"  /> }
              { article.cover.formats.medium && <source srcSet={Params.API_URL+article.cover.formats.medium.url} media="(width < 800px)"  /> }
              <img src={Params.API_URL+article.cover.url} alt="Illustration de l'article" />
            </picture>
        
            <ReactMarkdown className="content">{article.content}</ReactMarkdown>
          </div>
      }


    </main>
  )
}

export default Home