import React, { useState, useEffect } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import {default as ArticlesApi} from '../api/Articles';

export const About = () => {

  const [articles, setArticles] = useState([]);
  const [articlesLoading, setArticlesLoading] = useState(true);

  const getArticles = async () => {
    const filters = {
      category: {
        name: "Who"
      }
    }
    const { data, meta } = await ArticlesApi.getArticle(1, 5, filters);

    setArticles(data);
    setArticlesLoading(false);
  }

  useEffect(() => {
    getArticles()
  }, [])

  return (
    <main>
        { articles.map((a,i) => 
            <ReactMarkdown key={i}>{a.content}</ReactMarkdown>
        )}
    </main>
  )
}
