import React from 'react';
import { Outlet} from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';

import '../styles/Layout.css';

const Layout = () => {
  return (
    <div className="App">
        <Header />
        <Outlet />
        <Footer />
    </div>
  )
}

export default Layout